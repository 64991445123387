import React, {useState, useEffect} from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Backdrop from '@material-ui/core/Backdrop';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { DateTime } from "luxon";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 700,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  dialog:{
    animation: '$fadeIn 1 0.5s linear'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  newsDate: {
    [theme.breakpoints.up('sm')]: {
      textAlign: "right",
    }
  },
  newsTitle: {
    padding: theme.spacing(3),
  },
  '@keyframes fadeIn': {
    from: {
      opacity: 0
    },
    to: {
      opacity: 1
    }
  }
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  }
});

type NewsInfo = {
  title: string,
  create_at: string,
  body: string,
}

const convertShortDate = (date: String) => {
  return DateTime.fromISO(date).setLocale('ja').toLocaleString(DateTime.DATE_SHORT)
}

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const News = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allMicrocmsNews(sort: {order: DESC, fields: create_at}, limit: 5) {
          nodes {
            id
            title
            create_at
            body
          }
        }
      }
    ` 
  )

  // お知らせののopen, close管理
  const [open, setOpen] = useState(false);
  const [newsBody, setNewsBody] = useState<NewsInfo>({title: '', create_at: '', body: ''});
  const handleClose = () => {
    setOpen(false);
    setNewsBody({title: '', create_at: '', body: ''});
  };
  const handleToggle = (item: NewsInfo) => {
    setNewsBody(item);
    setOpen(!open);
  };

  const classes = useStyles();
  useEffect(() => {

  }, [newsBody]);

  return (
    <Container fixed className={classes.container}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        >
          <Typography variant="h3" gutterBottom>
            news.
          </Typography>
          <Grid
            container
            direction="column"
          >

            <List>
              {data.allMicrocmsNews.nodes.map( node =>
                <ListItem onClick={() => handleToggle(node)} key={node.id}>
                  <ListItemText
                    primary={`■${node.title}`}
                    secondary={convertShortDate(node.create_at)} 
                  />
                </ListItem>,
              )}
            </List>
          </Grid>
        <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
          <Dialog className={classes.dialog} onClose={handleClose} aria-labelledby="dialog-title" open={open}>
            <DialogTitle onClose={handleClose}>
              {newsBody.title}
            </DialogTitle>
            <DialogContent dividers>
              <Typography variant="body1">
                更新日：{convertShortDate(newsBody.create_at)}
              </Typography>
              <div dangerouslySetInnerHTML={{__html: newsBody.body}}></div>
            </DialogContent>
          </Dialog>
        </Backdrop>
      </Grid>
    </Container>
  );
}

export default News;
